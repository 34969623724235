export const DIRECT_CHAT_ROOM = "direct";
export const GROUP_CHAT_ROOM = "group_chat";
export const ENTER_KEY_CODE = 13;
export const ESCAPE_KEY_CODE = 27;
export const DOWN_KEY_CODE = 40;
export const UP_KEY_CODE = 38;
export const DELETE_KEY_CODE = 46;
export const BACKSPACE_KEY_CODE = 8;
export const GDPR_COOKIE_NAME = "circle_gdpr_cookies_enabled";
export const CIRCLE_DOMAIN = "circle.so";
export const STANDARD_LAYOUT_V2_COVER_IMAGE_CONTAINER =
  "standard-layout-v2-cover-image-container";

export const SOCIAL_MEDIA = {
  FACEBOOK: "facebook",
  TWITTER: "twitter",
  INSTAGRAM: "instagram",
  LINKEDIN: "linkedin",
} as const;

export const ASPECT_RATIO = {
  COMMUNITY_ICON: 1, // 1:1
  COMMUNITY_LOGO: 4, // 4:1
  COMMUNITY_LOGO_V3: 6.3888, // 115:18 (e.g., 230x36 or 460x72)
  COVER_V3: 3.2, // 16:5
  DEFAULT: 2.8, // 14:5
  OPEN_GRAPH: 1.9, // 19:10
  SPACE_COVER: 4.777, // 43:9
  THUMBNAIL: 2, // 2:1
};

export type AspectRatio = keyof typeof ASPECT_RATIO;

export const POST_FORM_CACHE = "posts-form-cache";

export const ONLINE_STATUS = {
  online: "online",
  idle: "idle",
  offline: "offline",
} as const;

export const MOUSETRAP_ACTIONS = {
  OPEN_SEARCH_MODAL: ["command+k", "ctrl+k"],
};

export const CHAT_THREADS_BANNER_LOCAL_STORAGE_KEY =
  "display-chat-threads-banner";

export const MESSAGING_MODAL_PORTAL_ID = "direct-messaging-modal";

export const HEADER_HEIGHT = 64;
export const V2_1_HEADER_HEIGHT = 48;
export const V3_HEADER_HEIGHT = 64;
export const SPACE_HEADER_HEIGHT = 72;
export const V3_SPACE_HEADER_HEIGHT = 72;
export const VIEW_AS_MASQUERADE_HEADER_HEIGHT = 48;
export const BOOKMARKS_HEIGHT = 41;
export const TRIAL_BANNER_HEIGHT = 48;
export const V3_OPT_IN_BAR_HEIGHT = 48;

export const MOBILE_BOTTOM_TOOLBAR_ID = "mobile-bottom-toolbar";
